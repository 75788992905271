'use strict';

module.exports = {
    initsortmodal: function () {
        $('.container').on('click', '.js-sort-modal', function () {
            $('.js-sort-modal-wrapper').addClass('show');
        });

        $('.js-sort-modal-wrapper').on('click', '.close', function () {
            $('.js-sort-modal-wrapper').removeClass('show');
        });

        $('.sortable-list').on('click', 'li a', function (e) {
            e.preventDefault();
            var $id = $(this).data('id');
            $('.js-sort-order-init option').removeAttr('selected', 'selected');
            $(".js-sort-order-init option[data-id='" + $id + "']").attr('selected', 'selected');
            $(".js-sort-order-init option[data-id='" + $id + "']").trigger('change');
            $('.js-sort-modal-wrapper').removeClass('show');
        });
    },
    refinementBar: function () {
        $('.container').on('click', 'button.filter-results', function (e) {
            e.preventDefault();
            $('.refinement-bar').toggleClass('show');
            $('.refinement-bar').siblings().attr('aria-hidden', true);
            $('.refinement-bar').closest('.row').siblings().attr('aria-hidden', true);
            $('.refinement-bar').closest('.tab-pane.active').siblings().attr('aria-hidden', true);
            $('.refinement-bar').closest('.container.search-results').siblings().attr('aria-hidden', true);
            $('.refinement-bar .close').focus();
        });
    }
};
