'use_strict';

module.exports = function () {
    // handling menu click function from the home page
    $('body').on('click', '.js-home-page-menu', function (e) {
        e.preventDefault();
        $.spinner().start();
        var $eml = $(e.target);
        $eml.addClass('active').siblings().removeClass('active');
        $.ajax({
            url: e.target.href,
            type: 'GET',
            success: function (data) {
                $('.container.home-product-tiles').replaceWith(data);
                $.spinner().stop();
            },
            error: function () {
                $.spinner().stop();
            }
        });
    });

    $('.js-currency-select').on('change', function () {
        var $cur = $(this);
        var localeCodeText = $cur.find('option:selected').text();
        $cur.attr('data-selected', localeCodeText);
        var $curText = $cur.attr('data-selected');
        var $preText = $cur.attr('data-prev-selected');

        if ($curText !== $preText) {
            var action = $('.page').data('action');
            var localeCode = $cur.find('option:selected').data('locale');
            var localeCurrencyCode = $cur.find('option:selected').val();

            var queryString = $('.page').data('querystring');
            var url = $cur.data('url');
            $.spinner().start();
            $.ajax({
                url: url,
                type: 'get',
                dataType: 'json',
                data: {
                    code: localeCode,
                    queryString: queryString,
                    CurrencyCode: localeCurrencyCode,
                    action: action
                },
                success: function (response) {
                    if (response && response.redirectUrl) {
                        window.location.href = response.redirectUrl;
                    }
                    $.spinner().stop();
                },
                error: function () {
                    $.spinner().stop();
                }
            });
        }
    });

    $('.js-language-select').on('change', function () {
        var $cur = $(this);
        var localeCodeText = $cur.find('option:selected').text();
        $cur.attr('data-selected', localeCodeText);
        var $curText = $cur.attr('data-selected');
        var $preText = $cur.attr('data-prev-selected');

        if (($curText !== $preText)) {
            var action = $('.page').data('action');
            var localeCode = $cur.find('option:selected').val();
            var localeCurrencyCode = $cur.find('option:selected').val();
            var queryString = $('.page').data('querystring');
            var url = $cur.data('url');
            $.spinner().start();
            $.ajax({
                url: url,
                type: 'get',
                dataType: 'json',
                data: {
                    code: localeCode,
                    queryString: queryString,
                    CurrencyCode: localeCurrencyCode,
                    action: action
                },
                success: function (response) {
                    $.spinner().stop();
                    if (response && response.redirectUrl) {
                        window.location.href = response.redirectUrl;
                    }
                },
                error: function () {
                    $.spinner().stop();
                }
            });
        }
    });
};
