'use strict';

module.exports = {
    initswitcher: function () {
        $(document).on('click', '.js-switcher-toggle', function (e) {
            e.preventDefault();
            $('.header-switcher').toggleClass('open-switcher');
        });

        $(document).on('click', '.header-switcher', function (e) {
            if ($(e.target).hasClass('header-switcher') && !$(e.target).hasClass('.switcher-item')) {
                $('.header-switcher').removeClass('open-switcher');
            }
        });
    },
    closeSwitcher: function () {
        $(document).on('click', '.js-switcher-closer', function (e) {
            e.preventDefault();
            $('.header-switcher').removeClass('open-switcher');
        });
    }
};
