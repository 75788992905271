/* eslint-disable new-cap */
'use strict';
// eslint-disable-next-line no-unused-vars, require-jsdoc
function searchProfileQuery(inp) {
    var $val = inp.toLowerCase();
    // var $newVal = $val.replace(/-/g, '');
    var niceIdea = [];
    $('#profile-search-results').empty();
    // $('#profile-search-results').empty().append
    $('.menu-group .account-box ul li a[data-search]').each(function () {
        var $data = $(this).data('search').toLowerCase();
        if ($data.includes($val)) {
            niceIdea.push('<div class="account-box smaller"><ul><li>' + $(this).get(0).outerHTML + '</li></ul></div>');
        }
    });

    for (var i = 0; i < niceIdea.length; ++i) {
        // do something with `substr[i]`
        var $this = niceIdea[i];
        $('#profile-search-results').append($this);
    }
}

module.exports = {
    searchProfile: function () {
        $(document).on('keyup', '.js-profile-search input', function () {
            var $this = $(this);
            var $val = $this.val();
            var $parent = $this.parent();

            if ($val.length > 0 && $val) {
                $parent.find('.close-profile-search').addClass('show');
                searchProfileQuery($val);
                $('.js-profile-search-hide').hide();
            } else {
                $parent.find('.close-profile-search').removeClass('show');
                $('.js-profile-search-hide').show();
                $('#profile-search-results').empty();
            }
        });
    },
    clearSearchProfile: function () {
        $(document).on('click', '.js-clear-profile-search', function () {
            var $this = $(this);
            $('.js-profile-search input').val('');
            $this.removeClass('show');
            $('.js-profile-search-hide').show();
            $('#profile-search-results').empty();
        });
    }
};
