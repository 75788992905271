/* eslint-disable no-undef */
'use_strict';

/**
 * function used to handle coupon code application on the frontend
 * @param {Object} data - AJAX response from the server
 */
function handleCouponCode(data) {
    // update UI with coupon code applied UI
    var couponData = [];
    for (var i = 0; i < data.totals.discounts.length; i++) {
        if (data.totals.discounts[i].type === 'coupon') {
            couponData.push({
                couponCode: data.totals.discounts[i].couponCode,
                UUID: data.totals.discounts[i].UUID
            });
        }
    }
    if (couponData.length > 0) {
        // coupon applied
        $('.js-cart-coupon-applied-area').empty();
        var cloneTemplate = $('.js-sample-ui-applied-template').clone();
        $(cloneTemplate).find('.js-applied-code').text(couponData[0].couponCode);
        $(cloneTemplate).find('.remove-coupon').attr('data-code', couponData[0].couponCode);
        $(cloneTemplate).find('.remove-coupon').attr('data-uuid', couponData[0].UUID);
        $(cloneTemplate).removeClass('d-none');
        cloneTemplate.appendTo('.js-cart-coupon-applied-area');
    } else {
        // coupon is not applied
        $('#promo-box-ui').removeClass('d-none');
        $('.js-cart-coupon-applied-area').empty();
    }
}

module.exports = {
    handleCouponCode: handleCouponCode
};
