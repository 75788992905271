'use_strict';
window.jQuery = window.$ = require('jquery');
var processInclude = require('base/util');

$(document).ready(function () {
    processInclude(require('./profileSearch'));
});

module.exports = function () {
    // handling menu click function from the home page
    $('body').on('click', '.js-language-selector', function (e) {
        e.preventDefault();
    });

    $('.navbar-toggler').click(function (e) {
        e.preventDefault();
        $('#header-side').toggleClass('openMenu');
    });

    $('.js-close-navigation').click(function (e) {
        e.preventDefault();
        $('.main-menu').toggleClass('in');
        $('.modal-background').show();

        $('.main-menu').removeClass('d-none');
        $('.main-menu').attr('aria-hidden', 'false');
        $('.main-menu').siblings().attr('aria-hidden', 'true');
        $('header').siblings().attr('aria-hidden', 'true');

        $('.main-menu .nav.navbar-nav .nav-link').first().focus();
        $('#header-side').toggleClass('openMenu');
    });

    var $header = $('#main-page-header');
    var $flag = false;
    $(window).scroll(function () {
        var scroll = $(window).scrollTop();
        if (scroll >= 1) {
            $flag = true;
        } else {
            $flag = false;
        }
        if ($flag) {
            $header.addClass('scrolled-header');
            return false;
            // eslint-disable-next-line no-else-return
        } else {
            $header.removeClass('scrolled-header');
            return false;
        }
    });
};
